import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/78a07ce7/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "responsive-image"
    }}>{`Responsive image`}</h2>
    <div className="responsive-image">
  <img src="/assets/drone.JPG" alt="Drone picture" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="responsive-image mbottom">
  <img src="/images/source.jpg" alt="" />
</div>
`}</code></pre>
    <h2 {...{
      "id": "responsive-youtube-iframe"
    }}>{`Responsive YouTube Iframe`}</h2>
    <div className="responsive-video">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/3ixSvX5-Nsw" frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="responsive-video">
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/3ixSvX5-Nsw"
    frameborder="0"
    gesture="media"
    allow="encrypted-media"
    allowfullscreen
  >
  </iframe>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      